import store, { YacXStore } from "@/store";
import { sleep } from "@/util";
import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";
import FirebaseService from "./FirebaseService";

const ANALYTICS_MODE_DEBUG = false;

let cachedSegmentAnalytics: Analytics | null = null;
let cachedIdentifiedUser: YacXStore["user"]["user"] | null = null;

export default class AnalyticsService extends FirebaseService {
  constructor() {
    super();
    if (ANALYTICS_MODE_DEBUG || process.env.NODE_ENV === "production") {
      if (cachedSegmentAnalytics) {
        this.segment = cachedSegmentAnalytics;
      } else {
        this.segmentLoading = true;
        AnalyticsBrowser.load({
          writeKey: "P5zYzrVKZz9Eqh5AqR9drNy4A5RYDlfd",
        })
          .then(([anal]) => {
            this.segment = anal;
            if (process.env.NODE_ENV === "development") {
              this.segment.debug(true);
            }
            cachedSegmentAnalytics = this.segment;
          })
          .finally(() => {
            this.segmentLoading = false;
          });
      }
    }
  }
  segment: Analytics | null = null;
  segmentLoading: boolean = true;
  GA = this.analytics;

  public async trackPage() {
    if (!this.segment && this.segmentLoading) await sleep(1000);
    if (this.segment) {
      this.segment.page();
    }
    if (ANALYTICS_MODE_DEBUG && process.env.NODE_ENV !== "production") {
      console.info("Tracked current page visit");
    }
  }

  public async identifyUser(user: YacXStore["user"]["user"]) {
    cachedIdentifiedUser = user;
    const usr = user ? { ...user } : null;
    if (usr) {
      delete usr.notionToken;
      delete usr.googleDocsToken;
    }
    if (usr) {
      this.GA.setUserId(usr.id);
      this.GA.setUserProperties(usr);
    }
    if (!this.segment && this.segmentLoading) await sleep(1000);
    if (this.segment && usr) {
      this.segment.identify(usr.id, usr);
    }
    if (ANALYTICS_MODE_DEBUG && process.env.NODE_ENV !== "production") {
      if (usr) console.info("Identified user", usr.id, usr);
      else console.info("Could not identify user, falsy user value", usr);
    }
  }

  public async recordAnalyticsEvent<
    T extends keyof ANALYTICS_EVENT,
    R extends ANALYTICS_EVENT[T]
  >(eventName: T, params: R) {
    try {
      if (!this.segment && this.segmentLoading) await sleep(1000);

      const getParams: (p: R) => Record<string, any> = (this
        .getEventParamsDictionary[eventName] || ((p: R) => ({ ...p }))) as (
        p: R
      ) => Record<string, any>;
      const fullParams = this.cleanEventParams({
        ...getParams(params),
      });

      const normalizedEventName =
        this.getAnalyticsEventNameDictionary[eventName] || eventName;

      this.GA.logEvent(normalizedEventName as string, {
        ...fullParams,
        debug_mode: process.env.NODE_ENV === "development",
      });
      if (this.segment) {
        this.segment.track(normalizedEventName as string, fullParams);
      }

      if (ANALYTICS_MODE_DEBUG && process.env.NODE_ENV !== "production") {
        console.info("Recorded event", normalizedEventName, fullParams);
      }
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.error(
          "AnalyticsService.recordAnalyticsEvent",
          eventName,
          params,
          error
        );
      }
    }
  }

  cleanEventParams(params: Record<string, any>) {
    const value = { ...params };
    for (const key in value) {
      if (value[key] !== false && !value[key]) {
        value[key] = "NONE_FOUND";
      } else if (typeof value[key] === "object") {
        this.cleanEventParams(value[key]);
      }
    }
    return value;
  }

  private getEventParamsDictionary: GetEventParamsDictionary = {
    SCREEN_SHARE_MESSAGE_SEND: (params) => {
      let authorEmail = "NONE_FOUND";
      if (
        this.segment &&
        this.segment.user().traits() &&
        typeof (this.segment.user().traits() || {}).email === "string"
      ) {
        authorEmail =
          String((this.segment.user().traits() || {}).email) || "NONE_FOUND";
      } else if (cachedIdentifiedUser && cachedIdentifiedUser.email) {
        authorEmail = cachedIdentifiedUser.email || "NONE_FOUND";
      }
      return { ...params, authorEmail };
    },
    VOICE_MESSAGE_SEND: (params) => {
      let authorEmail = "NONE_FOUND";
      if (
        this.segment &&
        this.segment.user().traits() &&
        typeof (this.segment.user().traits() || {}).email === "string"
      ) {
        authorEmail =
          String((this.segment.user().traits() || {}).email) || "NONE_FOUND";
      } else if (cachedIdentifiedUser && cachedIdentifiedUser.email) {
        authorEmail = cachedIdentifiedUser.email || "NONE_FOUND";
      }
      return { ...params, authorEmail };
    },
    MEETING_PARTICIPANT_INVITE: ({ meetingId, ...rest }) => {
      return { ...rest, group_id: meetingId, meetingId };
    },
    AUTOMATIC_ADD_MEETING_PARTICIPANT: ({ meetingId, ...rest }) => {
      return { ...rest, group_id: meetingId, meetingId };
    },
    LOGIN: (params) => {
      return { ...params };
    },
  };

  private getAnalyticsEventNameDictionary: GetAnalyticsEventNameDictionary = {
    LOGIN: "login",
  };
}

type GetEventParamsDictionary = {
  [key in ANALYTICS_EVENT_NAMES]?: (
    params: ANALYTICS_EVENT[key]
  ) => Record<string, any> | {};
};

type GetAnalyticsEventNameDictionary = {
  [key in ANALYTICS_EVENT_NAMES]?: string;
};

export type ANALYTICS_EVENT_NAMES = keyof ANALYTICS_EVENT;

export interface ANALYTICS_EVENT {
  //
  // MEETING
  //
  MEETING_CREATE?: {};
  MEETING_EXPORT: {
    method: "Google Docs" | "Notion";
    meetingId: string;
  };
  TITLE_EDIT?: {};
  INITIAL_CUSTOM_TITLE_SET?: {};

  //
  // SUMMARY AND NOTES
  //
  ADD_SUMMARY: {
    contentLength: number;
  };
  NOTES_PRESSED: {
    meetingId: string;
    contentLength: number;
  };

  //
  // MESSAGES
  //
  MESSAGE_DOWNLOAD: {
    meetingId: string;
  };
  SCREEN_SHARE_MESSAGE_SEND: {
    duration: number;
    meetingId: string;
    kickoff: boolean;
    // authorEmail: string; automatically calculated
  };
  VOICE_MESSAGE_SEND: {
    duration: number;
    meetingId: string;
    kickoff: boolean;
    // authorEmail: string; automatically calculated
  };
  UPLOAD_MESSAGE_SEND: {
    duration: number;
    meetingId: string;
    kickoff: boolean;
    // authorEmail: string; automatically calculated
  };
  MESSAGE_PLAYBACK: {
    meetingId: string;
  };
  TRANSCRIPT_TIMESTAMP_CLICK?: {};

  //
  // PARTICIPANTS
  //
  MEETING_PARTICIPANT_INVITE: {
    emailExtension: string;
    meetingId: string;
  };
  INVITE_LINK_COPY: {
    meetingId: string;
  };
  // different name
  AUTOMATIC_ADD_MEETING_PARTICIPANT: {
    meetingId: string;
  };

  APPLICATION_ERROR: {
    code: string;
    payload?: string;
  };

  //
  // AUTH
  //
  // different name
  LOGIN: {
    method: string;
    emailExtension: string;
    email: string;
  };
  // GOOGLE_LOGIN: {
  //   emailExtension: string;
  // };
  // GOOGLE_SIGN_UP: {
  //   emailExtension: string;
  // };
  // // different name
  // ANONYMOUS_LOGIN: {
  //   emailExtension: string;
  // };
  // // different name
  // ANONYMOUS_SIGNUP: {
  //   emailExtension: string;
  // };
  // // different name
  // NON_EXPIRING_MAGIC_LINK_LOGIN: {
  //   emailExtension: string;
  // };
}
