import { generateId, sleep, validateSlug } from "@/util";
import { BaseServiceError } from "./BaseServiceError";
import FirebaseService from "./FirebaseService";
import firebase from "firebase/app";
import UserService from "./UserService";
import { UnresolvedMeeting } from "@/models/Meeting";
import { UnresolvedVideo } from "@/models/Video";
import { UnresolvedChatMessage } from "@/models/ChatMessage";
import { UnresolvedParticipant } from "@/models/Participant";
import { Store } from "vuex";
import { YacXStore } from "@/store";
import AnalyticsService from "./AnalyticsService";
import { TranscriptionData } from "./LiveTranscriptionService";

const userService = new UserService();
const analyticsService = new AnalyticsService();

export default class MeetingService extends FirebaseService {
  async createMeeting(
    payload: {
      name: string;
      slug?: string;
    },
    userId: string
  ) {
    try {
      let name = payload.name;
      if (!name) {
        const dateComponent = new Date().toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
        });
        const probableName = `Untitled Meeting ${dateComponent}`;
        name = probableName;
      }
      if (payload.slug && !validateSlug(payload.slug)) {
        throw new MeetingServiceError("INVALID_SLUG");
      }
      let slug = payload.slug || generateId(6);
      let slugIsUnique = false;
      while (!slugIsUnique) {
        const qs = await this.meetingsCol
          .where("slug", "==", slug)
          .limit(1)
          .get();
        const [existing] = qs.docs;
        if (existing && payload.slug) {
          throw new MeetingServiceError("SLUG_ALREADY_EXISTS");
        } else if (!existing) {
          slugIsUnique = true;
        } else {
          slug = generateId(6);
        }
      }
      const id = generateId(8);
      const date = new Date();
      const meeting: Omit<UnresolvedMeeting, "participants"> = {
        slug,
        name: name.trim(),
        id,
        owner: this.db.doc(`users/${userId}`) as any,
        notes: "",
        domain: location.hostname,
        agendaItems: [],
        videoIndex: [],
        status: "OPEN",
        dateCreated: date,
        endNotes: "",
        users: [userId],
      };
      const meetingDoc = this.meetingsCol.doc(id);
      const batch = this.db.batch();
      batch.set(meetingDoc, meeting);
      const participant: UnresolvedParticipant = {
        id: generateId(8),
        user: this.usersCol.doc(userId) as any,
        role: "OWNER",
      };
      batch.set(
        meetingDoc.collection("participants").doc(participant.id),
        participant
      );

      await batch.commit();
      await analyticsService.recordAnalyticsEvent("MEETING_CREATE", {});
      return meeting;
    } catch (error) {
      console.error("MeetingService.createMeeting", error);
      if (error instanceof MeetingServiceError) {
        throw error;
      } else {
        throw new MeetingServiceError("COULD_NOT_CREATE_MEETING", error);
      }
    }
  }

  async archiveMeeting(id: string) {
    try {
      const payload: Pick<UnresolvedMeeting, "status"> = {
        status: "ARCHIVED",
      };
      await this.meetingsCol.doc(id).set(payload, { merge: true });
    } catch (error) {
      console.error("MeetingService.archiveMeeting", error);
      throw new MeetingServiceError("COULD_NOT_ARCHIVE_MEETING", error);
    }
  }

  async googleDocsExport(id: string) {
    // @ts-ignore
    const docsExportURL = new URL(process.env.VUE_APP_GOOGLE_DOCS_REDIRECT);

    docsExportURL.searchParams.append("meeting", id);
    if (firebase.auth().currentUser != null) {
      // @ts-ignore
      const token = await firebase.auth().currentUser.getIdToken();
      docsExportURL.searchParams.append("token", token);
    }
    await analyticsService.recordAnalyticsEvent("MEETING_EXPORT", {
      method: "Google Docs",
      meetingId: id,
    });
    await sleep(250);
    window.open(docsExportURL.href, "_blank");
  }

  async notionExport(id: string) {
    // @ts-ignore
    const docsExportURL = new URL(process.env.VUE_APP_NOTION_REDIRECT);

    docsExportURL.searchParams.append("meeting", id);
    if (firebase.auth().currentUser != null) {
      // @ts-ignore
      const token = await firebase.auth().currentUser.getIdToken();
      docsExportURL.searchParams.append("token", token);
    }
    await analyticsService.recordAnalyticsEvent("MEETING_EXPORT", {
      method: "Notion",
      meetingId: id,
    });
    await sleep(250);
    window.open(docsExportURL.href, "_blank");
  }

  async unarchiveMeeting(id: string) {
    try {
      const payload: Pick<UnresolvedMeeting, "status"> = {
        status: "OPEN",
      };
      await this.meetingsCol.doc(id).set(payload, { merge: true });
    } catch (error) {
      console.error("MeetingService.unarchiveMeeting", error);
      throw new MeetingServiceError("COULD_NOT_UNARCHIVE_MEETING", error);
    }
  }

  async setMeetingEndNotes(meetingId: string, endNotes: string) {
    try {
      await this.meetingsCol
        .doc(meetingId)
        .update({ endNotes: endNotes || "" });
    } catch (error) {
      console.error("MeetingService.setMeetingEndNotes", error);
      throw new MeetingServiceError("COULD_NOT_ADD_END_NOTES", error);
    }
  }

  async updateMeeting(id: string, payload: { name: string }) {
    try {
      await this.meetingsCol.doc(id).update(payload);
    } catch (error) {
      console.error("MeetingService.updateMeeting", error);
      throw new MeetingServiceError("COULD_NOT_UPDATE_MEETING", error);
    }
  }

  private async uploadVideo({
    file,
    onUploadProgress,
    refPath,
  }: {
    file: File;
    onUploadProgress: (progress: number) => void;
    refPath: string;
  }): Promise<string> {
    return new Promise((resolve, reject) => {
      const storageRef = this.storage.ref(refPath);
      const [fileType] = (file.type || "").split(";");
      let contentType = fileType.replace("x-matroska", "webm");
      let extension = contentType.split("/").pop() || "webm";
      extension = extension.split("-").pop() || "webm";
      if (contentType.includes("application")) {
        contentType = `video/${extension}`;
      }
      const uploadTask = storageRef.put(file, {
        contentType,
        customMetadata: {
          mimeType: fileType.replace("x-matroska", "webm"),
        },
      });
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onUploadProgress(progress);
        },
        (error) => {
          console.error("MeetingService.uploadVideo", error);
          reject(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  }

  async addVideo({
    meetingId,
    getNewVideoIndex,
    file,
    onUploadProgress,
    byUserId,
    video,
    duration,
    title,
    extension = "ANY",
    store,
    type,
    kickoff,
    liveTranscriptionData,
  }: {
    meetingId: string;
    getNewVideoIndex?: (newVideoId: string) => string[];
    file: File;
    onUploadProgress: (progress: number) => void;
    byUserId: string;
    video: boolean;
    title: string;
    duration: number;
    extension?: string;
    store: Store<YacXStore>;
    type: "SCREEN_SHARE" | "VOICE" | "UPLOAD";
    kickoff?: boolean;
    liveTranscriptionData?: TranscriptionData;
  }) {
    let url: string = "";
    const videoId = Date.now() + generateId(6);
    let ext = extension;
    if (ext === "ANY") {
      ext = file.type.split("/").pop() || "webm";
      ext = ext.split("-").pop() || "webm";
    }
    const refPath: string = `meetings/${meetingId}/videos/${videoId}__raw.${
      file.type ? (file.type.includes("matroska") ? "webm" : ext) : ext
    }`;
    store.commit("meeting/uploading", true);
    try {
      url = await this.uploadVideo({
        file,
        onUploadProgress,
        refPath,
      });
    } catch (error) {
      console.error("MeetingService.addVideo", error);
      throw new MeetingServiceError("COULD_NOT_UPLOAD_VIDEO", error);
    }
    try {
      const batch = this.db.batch();
      batch.update(this.meetingsCol.doc(meetingId), {
        videoIndex: getNewVideoIndex
          ? getNewVideoIndex(videoId)
          : firebase.firestore.FieldValue.arrayUnion(videoId),
      });
      const videoDoc: UnresolvedVideo = {
        id: videoId,
        title,
        url,
        refPath,
        video,
        by: this.usersCol.doc(byUserId) as any,
        status: "UPLOADED",
        duration,
        type,
        sentAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      };
      if (liveTranscriptionData) {
        videoDoc.dialogues = liveTranscriptionData.dialogues;
        videoDoc.transcript = liveTranscriptionData.transcript;
      }
      batch.set(
        this.meetingsCol.doc(meetingId).collection("videos").doc(videoId),
        videoDoc
      );
      if (type === "SCREEN_SHARE") {
        await analyticsService.recordAnalyticsEvent(
          "SCREEN_SHARE_MESSAGE_SEND",
          {
            meetingId,
            duration,
            kickoff: !!kickoff,
          }
        );
      } else if (type === "VOICE") {
        await analyticsService.recordAnalyticsEvent("VOICE_MESSAGE_SEND", {
          meetingId,
          duration,
          kickoff: !!kickoff,
        });
      } else if (type === "UPLOAD") {
        await analyticsService.recordAnalyticsEvent("UPLOAD_MESSAGE_SEND", {
          meetingId,
          duration,
          kickoff: !!kickoff,
        });
      }
      await batch.commit();
    } catch (error) {
      console.error("MeetingService.addVideo", error);
      throw new MeetingServiceError("COULD_NOT_ADD_VIDEO", error);
    }
    store.commit("meeting/uploading", false);
  }

  async setVideo(
    meetingId: string,
    videoId: string,
    payload: Partial<Pick<UnresolvedVideo, "title">>
  ) {
    try {
      await this.meetingsCol
        .doc(meetingId)
        .collection("videos")
        .doc(videoId)
        .update(payload);
    } catch (error) {
      console.error("MeetingService.setVideo", error);
      throw new MeetingServiceError("COULD_NOT_SET_VIDEO", error);
    }
  }

  async deleteVideo(meetingId: string, videoId: string) {
    try {
      await this.meetingsCol
        .doc(meetingId)
        .collection("videos")
        .doc(videoId)
        .delete();
    } catch (error) {
      console.error("MeetingService.deleteVideo", error);
      throw new MeetingServiceError("COULD_NOT_DELETE_VIDEO", error);
    }
  }

  async setVideoIndex(meetingId: string, newIndex: string[]) {
    try {
      const payload: Pick<UnresolvedMeeting, "videoIndex"> = {
        videoIndex: newIndex,
      };
      await this.meetingsCol.doc(meetingId).update(payload);
    } catch (error) {
      console.error("MeetingService.setVideoIndex", error);
      throw new MeetingServiceError("COULD_NOT_SET_VIDEO_INDEX", error);
    }
  }

  async setAgendaItems(
    meetingId: string,
    agendaItems: UnresolvedMeeting["agendaItems"]
  ) {
    try {
      await this.meetingsCol.doc(meetingId).update({
        agendaItems,
      });
    } catch (error) {
      console.error("MeetingService.setAgendaItems", error);
      throw new MeetingServiceError("COULD_NOT_SET_AGENDA_ITEMS", error);
    }
  }

  async sendChatMessage(
    meetingId: string,
    payload: { text: string; files?: File[] },
    byUserId: string
  ) {
    try {
      const id = Date.now() + "_" + generateId(3);
      const chatMessage: UnresolvedChatMessage = {
        id,
        ...payload,
        by: this.usersCol.doc(byUserId) as any,
        sentAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      };
      await this.meetingsCol
        .doc(meetingId)
        .collection("chat")
        .doc(id)
        .set(chatMessage);
    } catch (error) {
      console.error("MeetingService.sendChatMessage", error);
      throw new MeetingServiceError("COULD_NOT_SEND_CHAT_MESSAGE", error);
    }
  }

  async setMeetingNotes(meetingId: string, notes: string) {
    try {
      await this.meetingsCol.doc(meetingId).update({
        notes,
      });
    } catch (error) {
      console.error("MeetingService.setMeetingNotes", error);
      throw new MeetingServiceError("COULD_NOT_SET_MEETING_NOTES", error);
    }
  }
}

export type MeetingServiceErrorCode =
  | "COULD_NOT_CREATE_MEETING"
  | "COULD_NOT_ARCHIVE_MEETING"
  | "COULD_NOT_UNARCHIVE_MEETING"
  | "COULD_NOT_UPDATE_MEETING"
  | "COULD_NOT_UPLOAD_VIDEO"
  | "COULD_NOT_ADD_VIDEO"
  | "COULD_NOT_SET_VIDEO"
  | "COULD_NOT_DELETE_VIDEO"
  | "COULD_NOT_SET_VIDEO_INDEX"
  | "COULD_NOT_SET_AGENDA_ITEMS"
  | "COULD_NOT_SEND_CHAT_MESSAGE"
  | "COULD_NOT_ADD_MEETING_PARTICIPANT"
  | "SLUG_ALREADY_EXISTS"
  | "SLUG_MISSING"
  | "NAME_MISSING"
  | "INVALID_SLUG"
  | "COULD_NOT_SET_MEETING_NOTES"
  | "COULD_NOT_ADD_END_NOTES";

export class MeetingServiceError extends BaseServiceError<MeetingServiceErrorCode> {
  mapErrorCodeToMessage(Code: MeetingServiceErrorCode): string {
    switch (Code) {
      case "COULD_NOT_CREATE_MEETING":
        return "Event creation failed.";
      case "COULD_NOT_ARCHIVE_MEETING":
        return "Archiving the meeting failed.";
      case "COULD_NOT_UNARCHIVE_MEETING":
        return "Could not unarchive meeting.";
      case "COULD_NOT_UPDATE_MEETING":
        return "Could not update meeting.";
      case "COULD_NOT_UPLOAD_VIDEO":
        return "Could not upload video.";
      case "COULD_NOT_ADD_VIDEO":
        return "Could not add video to meeting.";
      case "COULD_NOT_DELETE_VIDEO":
        return "Could not delete video.";
      case "COULD_NOT_SET_VIDEO_INDEX":
        return "Could not set video index.";
      case "COULD_NOT_SET_AGENDA_ITEMS":
        return "Could not set agenda items.";
      case "COULD_NOT_SEND_CHAT_MESSAGE":
        return "Could not send chat message.";
      case "COULD_NOT_SET_MEETING_NOTES":
        return "Could not set meeting notes.";
      case "COULD_NOT_SET_VIDEO":
        return "Could not set video data.";
      case "COULD_NOT_ADD_MEETING_PARTICIPANT":
        return "Could not add meeting participant.";
      case "SLUG_ALREADY_EXISTS":
        return "That link is in use.";
      case "SLUG_MISSING":
        return "You must provide a link.";
      case "NAME_MISSING":
        return "Events are better with titles";
      case "INVALID_SLUG":
        return "Invalid link.";
      case "COULD_NOT_ADD_END_NOTES":
        return "Adding the summary failed. Sorry!";
      default:
        return "There has been an unknown error.";
    }
  }
}
