












































































import Vue from "vue";
import Modal from "../core/Modal.vue";
import Spinner from "../svg/Spinner.vue";
import PageHeaderText from "../typography/PageHeaderText.vue";
type VideoList = {
  downloadUrl: string;
  filename: string;
  name: string;
  size: string;
  error: string;
  index: number;
}[];
export default Vue.extend<any, any, any, any>({
  components: { Spinner, Modal, PageHeaderText },
  name: "DownloadMessageModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    meetingId: {
      type: String,
      required: true,
    },
    videoId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reloadCounter: 0,
      loading: false,
      error: "",
      videos: [] as VideoList,
    };
  },
  methods: {
    async getVideos() {
      try {
        this.loading = true;
        const url = `/meeting/videos`;
        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify({
            meetingId: this.meetingId,
            videoId: this.videoId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        console.log(response, data);
        if (data.videos)
          this.videos =
            data.videos &&
            (data.videos as VideoList).sort((a, b) => a.index - b.index);
        else this.error = "No file to download.";
      } catch (error) {
        console.error("DownloadMessageModal.getFiles", error);
        this.error = "Failed to get files";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    meetingAndVideoId() {
      return `${this.meetingId}-${this.videoId}-${this.reloadCounter}-${this.show}`;
    },
  },
  watch: {
    meetingAndVideoId() {
      this.getVideos();
    },
  },
});
