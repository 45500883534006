
















































import Vue from "vue";
import Modal from "@/components/core/Modal.vue";
import FormItem from "../form/FormItem.vue";
import Logo from "../svg/Logo.vue"; 
import PageHeaderText from "../typography/PageHeaderText.vue";

export default Vue.extend<any, any, any, any>({
  components: {
    Modal,
    Logo,
    FormItem,
    PageHeaderText,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "Login",
    },
    hideNameInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    meetingId: {
      type: String,
      required: false,
      default: "",
    },
    emailCompletionPrompt: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      error: "",
      loadingSetSavedUser: false,
      loginWithEmail: true,
      message: "",
      userEmailCopy: "",
      userRealName: "",
    };
  },
  methods: {
    googleLogin(){
      this.$store.dispatch("auth/googleLogin").then((returnObject:any)=>{
        console.log('RETURN OBJECT', returnObject);
        this.$emit('success');
        this.$emit("close");
      });
    },
    logInUser() {
      this.error = "";
      this.loadingSetSavedUser = true;
      // const prevUserId = this.user.id;
      this.$store.dispatch("auth/email", this.userEmailCopy).then((data:any|null)=>{
        if(data.data.message){
          this.message = data.data. message;
        }
        return Promise.resolve();
      })
      .catch((error: any) => {
        switch(error.response.status){
          case 409:
            console.log(error.response.data.provider);
            this.error = "This email address is already in use with an active account. Please login with this email to continue.";
            break;
          default:
            this.error = error.response.data.error || "Something went wrong.";
        }
      })
      .finally(() => {
        this.loadingSetSavedUser = false;
      });
    },
    cancelOperation() {
      this.$emit('cancel');
      this.$emit('close');
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user || {};
    },
  },
  watch: {
    user: {
      handler(user: any) {
        if (user.email) {
          this.userEmailCopy = user.email;
        }
      },
      deep: true,
    },
    showModal: {
      handler() {
        this.userEmailCopy = (this.$store.state.user.user || {}).email || "";
        this.loginWithEmail = false;
      },
    },
  },
  mounted() {
    this.userEmailCopy = (this.$store.state.user.user || {}).email || "";
  }
});
