




































































import Vue from "vue";
import FormItem from "./FormItem.vue";
import Button from "../core/Button.vue";
import { mapState } from "vuex";
export default Vue.extend({
  components: { FormItem, Button },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        name: "",
      }),
    },
    moreOptions: {
      type: Boolean,
      required: true,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("user", {
      user: "user",
    }),
  },
  watch: {
    value: {
      handler(newValue) {
        const value = Object.assign(this.value, newValue);
        this.$emit("input", value);
      },
      deep: true,
    },
  },
});
