, {
          once: true,
        }<template>
  <div
    class="notes relative overflow-y-scroll"
    @mousedown.passive="onNotesPressed"
  >
    <div
      v-if="disabled"
      class="z-[3] top-0 bottom-0 left-0 right-0 pl-3 absolute"
    />
    <div
      class="absolute z-[2] top-0 bottom-0 left-0 right-0 px-3 flex flex-col"
    >
      <MarkdownEditor
        ref="editor"
        theme="light"
        v-model="proxyNotes"
        @input="onNotesInput"
        class="flex flex-col flex-grow"
      />
    </div>
    <div class="px-3 opacity-30 absolute z-[1] top-0 bottom-0 left-0 right-0">
      <MarkdownEditor
        v-if="!proxyNotes || !proxyNotes.length"
        theme="light"
        :value="
          placeholder === 'notes'
            ? disabled
              ? `There were no notes for this meeting.`
              : `Event Agenda\n1.\n2.\n3.\nAction Items\n1.\n2.\n3.`
            : disabled
            ? `There was no summary for this meeting.`
            : `Write your summary…`
        "
      />
    </div>
  </div>
</template>

<script>
import MarkdownEditor from "@voraciousdev/vue-markdown-editor";
import AnalyticsService from "@/services/AnalyticsService";
const analyticsService = new AnalyticsService();
export default {
  components: {
    MarkdownEditor,
  },
  data() {
    return {
      proxyNotes: this.value,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.proxyNotes = newValue;
        this.$nextTick(() => {
          this.enhanceLinks();
        });
      },
      immediate: true,
    },
  },
  mounted() {
    console.log(this.proxyNotes);
    this.enhanceLinks();
    this.$nextTick(() => {
      this.enhanceLinks();
    });
  },
  methods: {
    onNotesInput() {
      if (!this.disabled) {
        this.$emit("input", this.proxyNotes);
      }
    },
    onNotesPressed() {
      this.enhanceLinks();
      if (!this.disabled && this.placeholder === "notes") {
        analyticsService.recordAnalyticsEvent("NOTES_PRESSED", {
          meetingId: this.meetingId,
          contentLength: this.proxyNotes.length,
        });
      }
      if (!this.proxyNotes) {
        this.$refs.editor.focusStart();
      }
    },
    enhanceLinks() {
      const links = document.querySelectorAll(".cm-link");
      links.forEach((link) => {
        link.addEventListener("click", () => {
          const includesHTTP =
            link.innerText.includes("https://") ||
            link.innerText.includes("http://");
          if (includesHTTP) {
            window.open(link.innerText, "_blank").focus();
          } else if (!includesHTTP) {
            window.open("https://" + link.innerText, "_blank").focus();
          }
        }, {
          once: true,
        });
        link.addEventListener("touchend", () => {
          const includesHTTP =
            link.innerText.includes("https://") ||
            link.innerText.includes("http://");
          if (includesHTTP) {
            window.open(link.innerText, "_blank").focus();
          } else if (!includesHTTP) {
            window.open("https://" + link.innerText, "_blank").focus();
          }
        }, {
          once: true,
        });
        link.style.cursor = "pointer";
      });
    },
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "notes",
      required: false,
      validator(value) {
        return ["notes", "endNotes"].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    meetingId: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>
<style>
.notes {
  font-size: 14px !important;
}
.cm-header {
  color: black !important;
}
.cm-m-markdown {
  color: black !important;
}
.CodeMirror-vscrollbar {
  display: none !important;
}

.CodeMirror-scroll {
  margin-right: 0px;
}

.cm-s-one-mixed .cm-m-markdown.cm-formatting-list {
  color: black !important;
}
.markdownInput {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  min-height: 500px;
}
.CodeMirror-cursor {
  margin-top: 6px !important;
  height: 16px !important;
}
</style>
