import { Getters, Mutations, Actions, Module } from "vuex-smart-module";
import firebase from "firebase/app";
import UserService, {
  UserInputModel,
  UserOutputModel,
} from "@/services/UserService";
import LocalStorageService from "@/services/LocalStorageService";
import { validateEmail } from "@/util";
import AnalyticsService from "@/services/AnalyticsService";
import store from "..";

const userService = new UserService();
const analyticsService = new AnalyticsService();
const localStorageService = new LocalStorageService();

export class UserState {
  user: UserOutputModel | null = localStorageService.loadBase64("user").user;
  loading: boolean = false;
  resolving: boolean = false;
  error: string | null = null;
  savedUserEmail: string | null =
    localStorageService.loadBase64("savedUserEmail").savedUserEmail;
  unsubscribe: null | firebase.Unsubscribe = null;
}

class UserGetters extends Getters<UserState> {
  nonNullUser() {
    return this.state.user || {};
  }
}

class UserMutations extends Mutations<UserState> {
  SET_USER(payload: UserOutputModel | null) {
    this.state.user = payload;
    localStorageService.saveBase64({ user: payload });
    if (payload) analyticsService.identifyUser(payload);
  }
  SET_LOADING(payload: boolean) {
    if (!this.state.user) this.state.loading = payload;
    this.state.resolving = payload;
  }
  SET_ERROR(payload: string | null) {
    this.state.error = payload;
  }
  SET_SAVED_USER_EMAIL(payload: string | null) {
    this.state.savedUserEmail = payload;
    localStorageService.saveBase64({ savedUserEmail: payload });
  }
}

class UserActions extends Actions<
  UserState,
  UserGetters,
  UserMutations,
  UserActions
> {
  async loadUser(user: firebase.User) {
    this.commit("SET_LOADING", true);
    if(this.state.unsubscribe) this.state.unsubscribe();
    this.state.unsubscribe = userService.subscribe(user.uid).onSnapshot((userRecord)=>{
      this.commit("SET_USER", userRecord.data() as UserOutputModel);
      this.commit("SET_LOADING", false);
      store.dispatch("auth/getCookie");
    });
    /*
    analyticsService.recordAnalyticsEvent(method, {
      emailExtension: user.email
        ? user.email.split("@")[1] || "NONE_FOUND"
        : "NONE_FOUND",
    });
    */
  }

  async updateUser(payload: {
    slug?: string;
    displayName?: string;
    email?: string;
  }) {
    if (!this.state.user) throw new Error("User is not logged in.");
    await userService.updateUser(this.state.user.id, payload);
  }

  async setSavedUserEmail(payload: {
    email: string;
    nonExpiringMagicLink?: boolean;
    removeUserWithId?: string;
  }): Promise<unknown> {
    if (this.state.savedUserEmail === payload.email) return;
    if (payload.email && !validateEmail(payload.email)) {
      throw new Error("Invalid email.");
    }
    // const currentEmail = this.state.user ? this.state.user.email : "";
    this.commit("SET_SAVED_USER_EMAIL", payload.email);
    // if (payload.email) {
    //   return this.dispatch("loadUser", {
    //     attemptMergeIntoCurrentUser: currentEmail ? false : true,
    //     method: payload.nonExpiringMagicLink
    //       ? "NonExpiringMagicLink"
    //       : undefined,
    //     removeUserWithId: payload.removeUserWithId,
    //   });
    // }
  }

  async getUserBySlug({ slug }: { slug: string }) {
    return await userService.getUserBySlug(slug);
  }

  clear() {
    this.commit("SET_USER", null);
    this.commit("SET_ERROR", null);
    this.commit("SET_LOADING", false);
    this.commit("SET_SAVED_USER_EMAIL", null);
  }
}

// Create a module with module asset classes
const UserModule = new Module({
  namespaced: true,
  state: UserState,
  getters: UserGetters,
  mutations: UserMutations,
  actions: UserActions,
});

export default UserModule;