import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/analytics";
import axios from "axios";

//PRODUCTION
const firebaseConfig = {
  apiKey: "AIzaSyDxAs7OaYcGEOEvmj7H5lNNY0cFF7Ie4Jo",
  authDomain: "yac-associated-press.firebaseapp.com",
  projectId: "yac-associated-press",
  storageBucket: "yac-associated-press.appspot.com",
  messagingSenderId: "397988405144",
  appId: "1:397988405144:web:6cfe02c831c93b1b39d5ed",
};

// //STAGING
// const firebaseConfig = {
//   apiKey: "AIzaSyAJSShf7CUPWlToETQttg17hwaFTqrNlFI",
//   authDomain: "yacx-staging.firebaseapp.com",
//   projectId: "yacx-staging",
//   storageBucket: "yacx-staging.appspot.com",
//   messagingSenderId: "1057560890624",
//   appId: "1:1057560890624:web:3e0811bb8bcc048dbb37e5",
//   measurementId: "G-0G0Y049NTF"
// };

export const app = firebase.initializeApp(firebaseConfig);

// if (location.hostname === "localhost") {
//   app.firestore().useEmulator("localhost", 9090);
//   app.storage().useEmulator("localhost", 9199);
//   app.auth().useEmulator("http://localhost:9099");
// }

export default class FirebaseService {
  auth = app.auth();
  db = app.firestore();
  analytics = app.analytics();
  storage = app.storage();
  providers = {
    Google: firebase.auth.GoogleAuthProvider,
    EmailAndPassword: firebase.auth.EmailAuthProvider,
  };
  meetingsCol = this.db.collection("meetings");
  usersCol = this.db.collection("users");
  userRelationsCol = this.db.collection("userRelations");
  public get currentUser() {
    return this.auth.currentUser;
  }
}

if(app.auth().isSignInWithEmailLink(window.location.href)){

  let loginEmail = JSON.parse(localStorage.getItem("yacx.email") || "null");
  if(!loginEmail){
    loginEmail = window.prompt('Please provide your email for confirmation');
  }

  if(!loginEmail){
    window.history.pushState([], "YacX", location.origin+location.pathname); 
  }else{
    const oldUser = app.auth().currentUser || getLocalStorageUser();

    app.auth().signInWithEmailLink(loginEmail, window.location.href).then(async (newUser) => {
      if(oldUser && oldUser.isAnonymous && newUser && newUser.user && !newUser.user.isAnonymous){
        await axios.post("/user/replace",{old: oldUser.id, new: newUser.user.uid});
      }

      window.localStorage.removeItem("yacx.email");
    })
    .then(()=>{
      window.history.pushState([], "YacX", location.origin+location.pathname); 
    })
    .catch((error) => {
      window.history.pushState([], "YacX", location.origin+location.pathname); 
    });
  }
}

function connectFirestoreEmulator(db: any, arg1: string, arg2: number) {
  throw new Error("Function not implemented.");
}

function getLocalStorageUser(){
  return JSON.parse(
    decodeURIComponent(
      Buffer.from(
        localStorage.getItem("yacx.user") || "bnVsbA==",
        "base64"
      ).toString("utf8")
    )
  );
}