import { Getters, Mutations, Actions, Module } from "vuex-smart-module";
import LiveTranscriptionService, {
  TranscriptionData,
} from "@/services/LiveTranscriptionService";

export class TranscriptionState {
  serviceInstance: LiveTranscriptionService | null = null;
  transcriptionData: TranscriptionData = {
    transcript: "",
    dialogues: [],
    words: [],
  };
  accessToken: string = "";
}

class TranscriptionGetters extends Getters<TranscriptionState> {}

class TranscriptionMutations extends Mutations<TranscriptionState> {
  SET_TRANSCRIPTION_DATA(payload: TranscriptionData) {
    this.state.transcriptionData = payload;
  }
  SET_ACCESS_TOKEN(payload: string) {
    this.state.accessToken = payload;
  }
  START_TRANSCRIPTION(payload: {
    meetingId: string;
    meetingTitle: string;
    onDataUpdate: (data: TranscriptionData) => void;
  }) {
    this.state.serviceInstance = new LiveTranscriptionService({
      ...payload,
      accessToken: this.state.accessToken,
    });
  }
  CLEAR_TRANSCRIPTION_DATA() {
    if (this.state.serviceInstance) {
      this.state.serviceInstance.stop();
      this.state.serviceInstance = null;
    }
    this.state.transcriptionData = {
      transcript: "",
      dialogues: [],
      words: [],
    };
  }
}

class TranscriptionActions extends Actions<
  TranscriptionState,
  TranscriptionGetters,
  TranscriptionMutations,
  TranscriptionActions
> {
  async getAccessToken() {
    const accessToken = await LiveTranscriptionService.getAccessToken();
    this.mutations.SET_ACCESS_TOKEN(accessToken);
    return accessToken;
  }
  startTranscription(payload: { meetingId: string; meetingTitle: string }) {
    if (!this.state.accessToken) throw new Error("Access token is not set.");
    this.mutations.START_TRANSCRIPTION({
      ...payload,
      onDataUpdate: this.mutations.SET_TRANSCRIPTION_DATA,
    });
  }
  sendStream(payload: { stream: MediaStream }) {
    if (this.state.serviceInstance) {
      this.state.serviceInstance.sendStream(payload.stream);
    } else {
      throw new Error("Transcription service is not started.");
    }
  }
  stopTranscription() {
    if (this.state.serviceInstance) {
      this.commit("CLEAR_TRANSCRIPTION_DATA");
    } else {
      throw new Error("Transcription service is not started.");
    }
  }
}

// Create a module with module asset classes
const TranscriptionModule = new Module({
  namespaced: true,
  state: TranscriptionState,
  getters: TranscriptionGetters,
  mutations: TranscriptionMutations,
  actions: TranscriptionActions,
});

export default TranscriptionModule;
