











































































































































































































import { ResolvedVideo } from "@/models/Video";
import { getTimeLabel } from "@/util";
import Vue from "vue";
import { mapState } from "vuex";
import Button from "../core/Button.vue";
import FormItem from "../form/FormItem.vue";
import Modal from "../core/Modal.vue";
import PageHeaderText from "../typography/PageHeaderText.vue";
import Transcript from "./Transcript.vue";
import MoreIcon from "@/components/svg/MoreIcon.vue";
import FloatingTooltip from "../core/FloatingTooltip.vue";
import AnalyticsService from "@/services/AnalyticsService";
import DownloadMessageModal from "./DownloadMessageModal.vue";
const analyticsService = new AnalyticsService();

export default Vue.extend<any, any, any, any>({
  components: {
    Button,
    Modal,
    MoreIcon,
    FloatingTooltip,
    PageHeaderText,
    FormItem,
    Transcript,
    DownloadMessageModal,
  },
  data() {
    return {
      show: false,
      showDelete: false,
      showDownload: false,
      messageCopy: {},
      actionStatusLabel: null as null | string,
      actionStatus: null as null | string,
      getTimeLabelInterval: null as null | number,
      timeLabel: "",
      actions: [],
    };
  },
  props: {
    message: {
      type: Object,
      required: true,
      default: () => ({
        id: "",
        by: {
          displayName: "",
          photoURL: "",
        },
        transcript: "",
        duration: 0,
        status: "",
      }),
    },
    liveMode: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    meetingId: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    videoReady: {
      type: Boolean,
      default: false,
    },
    meetingName: {
      type: String,
      required: false,
      default: "Untitled Event",
    },
  },
  methods: {
    parseStatus(status: ResolvedVideo["status"]) {
      return {
        UPLOADED: "Uploaded...",
        TRANSCODING: "Transcoding audio...",
        TRANSCODING_ERROR: "Transcoding failed.",
        TRANSCRIBING_ERROR: "Transcript processing failed.",
        TRANSCRIBING: "Processing transcript...",
        FINISHED: "Finished",
      }[status];
    },
    updateMessage() {
      this.actionStatusLabel = "Updating Message...";
      this.actionStatus = "LOADING";
      this.$store
        .dispatch("meeting/setVideo", {
          ...this.messageToUpdate,
          meetingId: this.meetingId,
        })
        .then(() => {
          this.actionStatusLabel = "Video Updated";
          this.actionStatus = "SUCCESS";
          setTimeout(() => {
            this.actionStatusLabel = null;
          }, 3000);
        })
        .catch((error: any) => {
          this.actionStatusLabel = error.message || "Could not update message.";
          this.actionStatus = "ERROR";
        });
    },
    deleteMessage() {
      this.actionStatusLabel = "Deleting Message...";
      this.actionStatus = "LOADING";
      this.$store
        .dispatch("meeting/deleteVideo", {
          id: this.message.id,
          meetingId: this.meetingId,
        })
        .then(() => {
          this.actionStatusLabel = "Delete Successful";
          this.actionStatus = "SUCCESS";
          setTimeout(() => {
            this.actionStatusLabel = null;
          }, 3000);
        })
        .catch((error: any) => {
          this.actionStatusLabel = error.message || "Could not delete message.";
          this.actionStatus = "ERROR";
        });
    },
    downloadMessage() {
      analyticsService.recordAnalyticsEvent("MESSAGE_DOWNLOAD", {
        meetingId: this.meetingId,
      });
    },
  },
  watch: {
    messageByUser(v) {
      if (
        v &&
        !this.actions.filter((x: any) => x.name === "deleteMessage").length
      ) {
        this.actions.push({
          name: "deleteMessage",
          label: "Delete Message",
          disabled: false,
        });
      }
    },
    videoReady(v) {
      if (
        this.message.url &&
        v &&
        !this.actions.filter((x: any) => x.name === "downloadMessage").length
      ) {
        this.actions.push({
          name: "downloadMessage",
          label: "Download Message",
          disabled: false,
        });
      }
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    messageByUser() {
      return this.user && this.user.id === this.message.by.id;
    },
    messageToUpdate() {
      return {
        id: this.message.id,
        title: this.messageCopy.title,
      };
    },
    downloadFileName() {
      const firstPart = this.user
        ? this.user.realName || this.user.displayName
        : "Anonymous";
      const secondPart = this.meetingName;
      const thirdPart = this.message.sentAt.toDate().toISOString().slice(0, 10);
      let file = [firstPart, secondPart, thirdPart].join("-").replace(" ", "_");
      const extension = this.message.url
        .split(/[#?]/)[0]
        .split(".")
        .pop()
        .trim();
      if (extension) file += "." + extension;
      return file;
    },
  },
  mounted() {
    this.messageCopy = Object.assign({}, this.message);
    const timeLabel = getTimeLabel(this.message.sentAt);
    const pollingInterval = timeLabel.includes("second") ? 1000 : 60000;
    this.timeLabel = timeLabel;
    this.getTimeLabelInterval = setInterval(() => {
      this.timeLabel = getTimeLabel(this.message.sentAt);
    }, pollingInterval);
  },
  beforeDestroy() {
    clearInterval(this.getTimeLabelInterval);
  },
});
