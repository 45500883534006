




























































import CreateMeetingForm from "@/components/form/CreateMeetingForm.vue";
import Vue from "vue";
import Header from "@/components/core/Header.vue";
import Footer from "@/components/core/Footer.vue";
import PageHeaderText from "@/components/typography/PageHeaderText.vue";
import { copyToClipboard, sleep } from "@/util";
import AnalyticsService from "@/services/AnalyticsService";
import UserMeetings from "@/components/meeting/UserMeetings.vue";

const analyticsService = new AnalyticsService();

export default Vue.extend({
  components: {
    CreateMeetingForm,
    Header,
    Footer,
    PageHeaderText,
    UserMeetings,
  },
  metaInfo: {
    title: "Yac | Create A New Async Meeting",
  },
  data() {
    return {
      meeting: {
        name: "",
      },
      error: false as false | string,
      success: false as false | string,
      loading: false,
      moreOptions: false,
      inMove: false,
      activeSection: 0,
      offsets: [],
      touchStartY: 0,
    };
  },
  methods: {
    createAndCopyLink() {
      this.loading = true;
      this.error = false;
      this.success = false;
      this.$store
        .dispatch("meeting/createMeeting", this.meeting)
        .then((meeting) => {
          if (meeting.name) {
            analyticsService.recordAnalyticsEvent(
              "INITIAL_CUSTOM_TITLE_SET",
              {}
            );
          }
          try {
            copyToClipboard(`${window.location.origin}/m/${meeting.slug}`);
            this.success = "Copied to clipboard!";
          } catch (error: any) {
            this.error = error.message || "Could not copy link to clipboard.";
          }
          return sleep(1800).then(() => meeting);
        })
        .then((meeting) => {
          // open new tab only in chrome 
          if (window.navigator.userAgent.indexOf("Chrome") > -1) {
            window.open(`/m/${meeting.slug}`, "_blank");
          } else {
            this.$router.push(`/m/${meeting.slug}`);
          }
        })
        .catch((err: Error) => {
          this.error = err.message || "Failed to create meeting.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
