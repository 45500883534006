






































import { ResolvedVideo } from "@/models/Video";
import AnalyticsService from "@/services/AnalyticsService";
import LocalStorageService from "@/services/LocalStorageService";
import Vue from "vue";
import Message from "./Message.vue";

const analyticsService = new AnalyticsService();
const localStorage = new LocalStorageService();

export default Vue.extend<any, any, any, any>({
  components: { Message },
  data() {
    return {
      playingVideoId: 0,
      audioOnlyIds: [],
      activeMessageId: "",
      messageExtraProps: {},
      lastSelected: null,
      loadedDataIds: [],
    };
  },
  props: {
    messages: [],
    meetingId: {
      type: String,
      required: true,
    },
    meetingName: {
      type: String,
      required: false,
      default: "Untitled Event",
    },
    isRecording: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    isRecording(isRecording, wasRecording) {
      if (isRecording && !wasRecording) {
        this.pauseAllVideos();
      }
    },
    playingVideoId(v: number) {
      localStorage.save({ playingVideoId: v });
      setTimeout(() => {
        const el = document.getElementById(`message-${v}`);
        const meetingHeader = document.getElementById("meetingHeader");
        if (el && meetingHeader) {
          const navbarOffset = meetingHeader.getBoundingClientRect().height + 5;
          const totalOffset =
            el.getBoundingClientRect().top + window.pageYOffset - navbarOffset;
          window.scrollTo({ top: totalOffset, behavior: "smooth" });
        }
      }, 100);
    },
    activeMessageId(v: any) {
      const index = this.messages.findIndex((m: any) => m.id === v);
      console.log("activeMessageId", this.$refs.players);
      this.$refs.players.forEach((p: any, i: number) => {
        if (i !== index) p.player.pause();
      });
      this.playingVideoId = v;
    },
  },
  methods: {
    getVideoSrc(msg: ResolvedVideo) {
      return msg.url;
    },
    hasVideo(messageId: string) {
      const video = document.getElementById(`video-${messageId}`) as any | null;
      if (!video) return true;
      return (
        (video && video.mozHasVideo) ||
        Boolean(video.webkitVideoDecodedByteCount) ||
        Boolean(video.videoTracks && video.videoTracks.length)
      );
    },
    setNewSpeed(speed: number) {
      if (this.$refs.players) {
        this.$refs.players.forEach((p: any) => {
          if (p && p.player) p.player.speed = speed;
        });
      }
    },
    onVideoLoadedData({
      index,
      message,
    }: {
      message: ResolvedVideo;
      index: number;
    }) {
      if (!this.loadedDataIds.includes(message.id)) {
        this.loadedDataIds.push(message.id);
      }
      if (this.$refs.players[index].player.isAudio) {
        this.audioOnlyIds.push(message.id);
      }
      if (this.$refs.players[index].player) {
        this.$refs.players[index].player.on("ratechange", (e: any) => {
          this.setNewSpeed(e.detail.plyr.speed);
        });
      }
    },
    onTimeUpdate(
      {
        message,
      }: {
        message: ResolvedVideo;
        index: number;
      },
      e: any
    ) {
      const time = Math.floor(e.target.currentTime);
      if (time !== this.messageExtraProps[message.id + "currentTime"]) {
        Vue.set(this.messageExtraProps, message.id + "currentTime", time);
      }
    },
    onSetCurrentTime({ messageId, time }: { messageId: string; time: number }) {
      const index = this.messages.findIndex((m: any) => m.id === messageId);
      this.$refs.players[index].player.currentTime = time;
      this.$refs.players[index].player.play();
    },
    onMessagePlay(selected: { message: any; index: number }) {
      this.lastSelected = selected.index;
      this.$refs.players.forEach((p: any, i: number) => {
        if (i !== selected.index) p.player.pause();
      });
      analyticsService.recordAnalyticsEvent("MESSAGE_PLAYBACK", {
        meetingId: this.meetingId,
      });
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        this.$emit("changeOrientation", {
          position: "center",
          element: this.lastSelected,
        });
      } else if (orientation === "landscape-primary") {
        this.$emit("changeOrientation", {
          position: "end",
          element: this.lastSelected,
        });
      }
    },
    pauseAllVideos() {
      this.$refs.players.forEach((p: any) => {
        if (p && p.player) p.player.pause();
      });
    },
  },
  mounted() {
    const { playingVideoId } = localStorage.load("playingVideoId");
    this.playingVideoId = playingVideoId;
    window.addEventListener("orientationchange", this.handleOrientationChange);
  },
  beforeDestroy() {},
});
