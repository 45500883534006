import md5 from "crypto-js/md5";
import firebase from "firebase";

import { Decoder, tools, Reader } from "ts-ebml";


export function generateId(
  len: number = 10,
  chars: string = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_"
) {
  let id = "";
  for (let i = 0; i < len; i++) {
    id += chars[Math.floor(Math.random() * chars.length)];
  }
  return id;
}

export const parseUndefIntoNull = (value: Record<string, any>) => {
  for (const key in value) {
    if (value[key] === undefined) {
      value[key] = null;
    } else if (typeof value[key] === "object") {
      parseUndefIntoNull(value[key]);
    }
  }
  return value;
};

export const removeNulls = (value: Record<string, any>) => {
  for (const key in value) {
    if (value[key] === null) {
      delete value[key];
    } else if (typeof value[key] === "object") {
      removeNulls(value[key]);
    }
  }
  return value;
};

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

/**
 * Works like object.assign, but copies properties only present in source or target,
 * and also fills a property if value is undefined.
 */
export function assign<
  T extends Record<string, any>,
  K extends keyof T = keyof T
>(target: Partial<T>, source: Partial<T>): T {
  const result: Partial<T> = {};
  const totalKeys = Object.keys(target).concat(Object.keys(source)) as K[];
  for (const key of totalKeys) {
    if (nullOrUndefined(result[key])) {
      result[key] = source[key] || target[key];
    }
  }
  return result as T;
}

export const nullOrUndefined = (value: any) =>
  value === null || value === undefined;

export const validateEmail = (email: string) => {
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  return re.test(String(email).toLowerCase());
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function getHash(text: string) {
  const hash = md5(text.toLowerCase().trim());
  return hash.toString();
}

export const getRandomArrayElement = <T = any>(arr: T[]): T =>
  arr[Math.floor(Math.random() * arr.length)];

export function getRandomColor() {
  const colors = [
    "#0147FA",
    "#37FDFC",
    "#3A5FCD",
    "#43D58C",
    "#CD2990",
    "#CD6889",
    "#0044ff",
    "#53fe5c",
    "#7ded17",
    "#ff7124",
    "#d90166",
    "#ffd700",
    "#fcd116",
    "#ffc324",
    "#dfff4f",
  ];
  return getRandomArrayElement(colors);
}

export async function resizeImg(
  file: Blob,
  type: string,
  {
    maxWidth,
    maxHeight,
  }: {
    maxWidth: number;
    maxHeight: number;
  }
): Promise<File> {
  const img = document.createElement("img");
  img.src = await new Promise<any>((resolve) => {
    const reader = new FileReader();
    reader.onload = (e: any) => resolve(e.target.result);
    reader.readAsDataURL(file);
  });
  await new Promise((resolve) => (img.onload = resolve));
  const canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  if (ctx) ctx.drawImage(img, 0, 0);
  const MAX_WIDTH = maxWidth;
  const MAX_HEIGHT = maxHeight;
  let width = img.naturalWidth;
  let height = img.naturalHeight;
  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
  }
  canvas.width = width;
  canvas.height = height;
  ctx = canvas.getContext("2d");
  if (ctx) ctx.drawImage(img, 0, 0, width, height);
  const url = canvas.toDataURL(type);
  return dataUrlToFile(url, type);
}

export async function cropImageFile(
  file: File,
  aspectRatio: number,
  maxWidth?: number
): Promise<File> {
  // we return a Promise that gets resolved with our canvas element
  let resizedFile = file;
  if (maxWidth) {
    resizedFile = await resizeImg(file, file.type, {
      maxWidth,
      maxHeight: maxWidth / aspectRatio,
    });
  }
  const url = URL.createObjectURL(file);

  return new Promise((resolve) => {
    // this image will hold our source image data
    const inputImage = new Image();

    // we want to wait for our image to load
    inputImage.onload = () => {
      // let's store the width and height of our image
      const inputWidth = inputImage.naturalWidth;
      const inputHeight = inputImage.naturalHeight;

      // get the aspect ratio of the input image
      const inputImageAspectRatio = inputWidth / inputHeight;

      // if it's bigger than our target aspect ratio
      let outputWidth = inputWidth;
      let outputHeight = inputHeight;

      if (inputImageAspectRatio > aspectRatio) {
        outputWidth = inputHeight * aspectRatio;
      } else if (inputImageAspectRatio < aspectRatio) {
        outputHeight = inputWidth / aspectRatio;
      }

      // calculate the position to draw the image at
      const outputX = (outputWidth - inputWidth) * 0.5;
      const outputY = (outputHeight - inputHeight) * 0.5;

      // create a canvas that will present the output image
      const outputImage = document.createElement("canvas");

      // set it to the same size as the image
      outputImage.width = outputWidth;
      outputImage.height = outputHeight;

      // draw our image at position 0, 0 on the canvas
      const ctx = outputImage.getContext("2d");
      if (ctx) ctx.drawImage(inputImage, outputX, outputY);

      const outputUrl = outputImage.toDataURL("image/jpeg");
      dataUrlToFile(outputUrl, "image/jpeg").then(resolve);
    };

    // start loading our image
    inputImage.src = url;
  });
}

export async function dataUrlToFile(url: string, type: string) {
  try {
    const res = await fetch(url);
    const blob = await res.arrayBuffer();
    const filename = generateId(10) + type.split("/").pop();
    const file = new File([blob], filename, {
      type,
      lastModified: Date.now(),
    });
    return file;
  } catch (error) {
    console.error("util.dataUrlToFile", error);
    throw new Error("Could not generate file.");
  }
}

const timeouts = new Map<string, NodeJS.Timeout>();
export const debounce = (
  id: string,
  fn: (...args: any) => any,
  delay: number
) => {
  let timeoutID = null as null | NodeJS.Timeout;
  if (timeouts.has(id)) {
    timeoutID = timeouts.get(id) || null;
  } else if (timeoutID) {
    timeouts.set(id, timeoutID);
  }
  return (...args: any) => {
    if (timeoutID) clearTimeout(timeoutID);
    timeoutID = setTimeout(() => fn(...args), delay);
    timeouts.set(id, timeoutID);
  };
};

export const readAsArrayBuffer = (blob: Blob): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => {
      resolve(reader.result as ArrayBuffer);
    };
    reader.onerror = (ev) => {
      console.error("util.readAsArrayBuffer error", ev);
      reject(ev);
    };
  });
};

export const validateSlug = (slug: string) => {
  const re = /^[a-z0-9A-Z-_]+$/;
  return re.test(slug);
};

export const getAuthLoadingMessage = () => {
  const messages = [
    "Getting your user details...",
    "Verifying your identity...",
    "Connecting to the server...",
    "Loading your user data...",
    "Authenticating your session...",
  ];
  return getRandomArrayElement(messages);
};

export const injectMetadata = async (blob: Blob) => {
  const decoder = new Decoder();
  const reader = new Reader();
  reader.logging = false;
  reader.drop_default_duration = false;

  const buffer = await readAsArrayBuffer(blob);
  // https://github.com/legokichi/ts-ebml/issues/33
  let elms = decoder.decode(buffer);
  // start workaround code
  const validEmlType = ["m", "u", "i", "f", "s", "8", "b", "d"];
  elms = elms.filter((elm: { type: string }) =>
    validEmlType.includes(elm.type)
  );
  // end workaround code
  elms.forEach((elm: any) => {
    reader.read(elm);
  });

  reader.stop();
  const refinedMetadataBuf = tools.makeMetadataSeekable(
    reader.metadatas,
    reader.duration,
    reader.cues
  );

  const body = buffer.slice(reader.metadataSize);
  return new Blob([refinedMetadataBuf, body], {
    type: blob.type,
  });
};

export function getTimeLabel(timestamp?: firebase.firestore.Timestamp): string {
  if (!timestamp) return "right now";
  const now = new Date();
  const date = timestamp.toDate();
  const diff = now.getTime() - date.getTime();
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(diff / (1000 * 60 * 60));
  const diffMinutes = Math.floor(diff / (1000 * 60));
  const diffSeconds = Math.floor(diff / 1000);
  if (diffDays > 0) {
    if (diffDays === 1) {
      return "yesterday";
    } else if (diff <= 365) {
      return date.toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      });
    } else if (diffDays < 31) {
      return date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return `${diffDays} days ago`;
    }
  } else if (diffHours > 0) {
    return `${diffHours} ${diffHours === 1 ? "hr" : "hrs"} ago`;
  } else if (diffMinutes > 0) {
    return `${diffMinutes} ${diffMinutes === 1 ? "min" : "min"} ago`;
  } else if (diffSeconds > 0) {
    return `${diffSeconds} ${diffSeconds === 1 ? "sec" : "secs"} ago`;
  } else {
    return "just now";
  }
  return "right now";
}

export function fallbackCopyToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    if (!document.execCommand("copy")) {
      throw new Error("Could not copy text to clipboard.");
    }
  } catch (err) {
    console.error("util.fallbackCopyToClipboard err", err);
    throw new Error("Could not copy text to clipboard.");
  }

  document.body.removeChild(textArea);
}

export function copyToClipboard(text: string) {
  if (!navigator || !navigator.clipboard) {
    return fallbackCopyToClipboard(text);
  }
  try {
    navigator.clipboard.writeText(text).then(
      function () { },
      function () {
        throw new Error("Could not copy text to clipboard.");
      }
    );
  } catch (error) {
    throw new Error("Could not copy text to clipboard.");
  }
}
