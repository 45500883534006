
































import Vue from "vue";
import Header from "@/components/core/Header.vue";
import Footer from "@/components/core/Footer.vue";
import PageHeaderText from "@/components/typography/PageHeaderText.vue";
import Button from "@/components/core/Button.vue";

export default Vue.extend({
  components: {
    Header,
    Footer,
    PageHeaderText,
    Button,
  },
  methods: {
    redirectToNew() {
      this.$router.push("/new");
    },
  },
});
