import { Getters, Mutations, Actions, Module } from "vuex-smart-module";
import AuthService from "@/services/AuthService";
import firebase from "firebase";
import store from "..";
import router from "@/router";
import LocalStorageService from "@/services/LocalStorageService";
import Axios from "axios";

// What is this?
const NO_EMULATORS = true;
let axios = Axios.create();
if (process.env.NODE_ENV === "development" && NO_EMULATORS) {
  axios = Axios.create({
    baseURL: "https://ap.yac.com",
  });
}

const authService = new AuthService();
const localStorageService = new LocalStorageService();

export class AuthState {}

class AuthGetters extends Getters<AuthState> {}

class AuthMutations extends Mutations<AuthState> {}

class AuthActions extends Actions<
  AuthState,
  AuthGetters,
  AuthMutations,
  AuthActions
> {
  async email(email:string){
    if(authService.currentUser){
      const token = await authService.currentUser.getIdToken(true);
      return Axios.post("/auth/email",{ email, token });
    }else{
      throw Error("Auth service not loaded.");
    }
  }

  async login(email:string){
    localStorageService.save({ email });
    
    const actionCodeSettings = {
      url: window.location.origin + router.currentRoute.fullPath,
      handleCodeInApp: true,
      // iOS: {
      //   bundleId: 'com.example.ios'
      // },
      // android: {
      //   packageName: 'com.example.android',
      //   installApp: true,
      //   minimumVersion: '12'
      // },
      // dynamicLinkDomain: 'example.page.link'
    };
    return authService.auth.sendSignInLinkToEmail(email, actionCodeSettings);
  }

  async googleLogin(){
    const provider = new firebase.auth.GoogleAuthProvider();
    const userChangePromise = new Promise((resolve)=>{
      const oldUser = authService.currentUser;
      authService.auth.signInWithPopup(provider).then(async (newUser)=>{
        if(oldUser && oldUser.isAnonymous && newUser && newUser.user && !newUser.user.isAnonymous){
          await Axios.post("/user/replace",{old: oldUser.uid, new: newUser.user.uid});
        }
        resolve({oldUser, newUser});
      });
    });
    return userChangePromise;
  }

  async getCookie(){
    console.log("getCookie");
    const url = "/auth/login";

    try{
      if(authService.currentUser){
        const token = await authService.currentUser.getIdToken();
        const response = await Axios.post(url, { token }, {withCredentials: true});
        return response.data;
      }
    }catch(e:any){
      if(e.response.status === 401){
        store.dispatch("auth/signInAnonymously");
      }
    }
  }

  async tokenLogin(token:string){
    await authService.signInWithToken(token);
  }

  async linkWithGoogle() {
    if (!authService.currentUser) {
      throw new Error("We couldn't find your profile.");
    }
    await authService.currentUser.linkWithPopup(
      new authService.providers.Google()
    );
  }
  async linkWithEmailAndPassword(payload: { email: string; password: string }) {
    if (!authService.currentUser) {
      throw new Error("We couldn't find your profile.");
    }
    await authService.currentUser.linkWithCredential(
      authService.providers.EmailAndPassword.credential(
        payload.email,
        payload.password
      )
    );
  }

  async loggedIn(){
    console.log("loggedIn");
    if(authService.currentUser){
      const token = await authService.currentUser.getIdToken();
      const url = "/auth/login";
      
      try{
        await Axios.post(url, { token }, {withCredentials: true});
      }catch(e:any){
        if(e.response.status === 401){
          store.dispatch("auth/signInAnonymously");
        }
      }
    }
  }

  async signInAnonymously() {
    store.commit("user/SET_LOADING", true);
    await authService.signInAnonymously();
  }

  async logout() {
    await authService.logout();
    store.dispatch("user/clear");
  }
}

// Create a module with module asset classes
const AuthModule = new Module({
  namespaced: true,
  state: AuthState,
  getters: AuthGetters,
  mutations: AuthMutations,
  actions: AuthActions,
});

export default AuthModule;
